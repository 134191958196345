<template>
  <svg
    :width="size.width"
    :height="size.height"
    viewBox="0 0 188 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2974_27893"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      :width="size.width"
      :height="size.height"
    >
      <circle
        cx="94"
        cy="94"
        r="94"
        fill="#0A0A0A"
      />
    </mask>
    <g mask="url(#mask0_2974_27893)">
      <circle
        cx="94"
        cy="94.3435"
        r="94"
        fill="url(#paint0_linear_2974_27893)"
      />
      <path
        d="M111.153 58.0603C109.18 64.6219 103.934 70.1399 99.9078 73.5787C97.0669 76.0333 94.8274 77.4373 94.8274 77.4373L85.6955 102.578L84.733 105.23C84.733 105.23 85.7462 109.407 86.573 116.252C87.7349 125.725 88.5479 140.305 85.8534 155.976L85.8437 156.042C83.9375 167.129 80.2879 178.758 73.7615 189.499C62.9892 207.237 44.5024 211.74 28.0225 208.986C17.8583 207.292 8.45686 202.85 2.09394 197.062C-2.70538 192.691 -5.76861 187.561 -6.12296 182.274C-7.72056 158.569 9.64332 162.397 34.5175 129.899C44.854 116.398 47.1967 106.251 46.678 97.7677C45.9646 85.8303 39.5885 77.2088 41.8556 67.2455C45.7327 50.2099 74.2608 32.5619 92.0905 34.8038C105.87 36.5302 111.749 42.7629 112.154 50.6946C112.28 53.029 111.917 55.506 111.153 58.0603Z"
        fill="#FD6B51"
      />
      <path
        d="M85.7026 155.885L85.6928 155.952C83.787 167.051 80.1499 178.695 73.6584 189.454C62.9436 207.221 44.5876 211.751 28.2327 209.015C16.3117 200.732 14.6112 182.013 31.887 165.606C60.569 138.381 53.2623 94.574 60.453 83.4601C67.6342 72.3447 82.2022 74.2254 82.2022 74.2254L83.9534 86.2692C83.9534 86.2692 84.8302 92.6127 85.612 102.44C85.9375 106.481 86.2452 111.105 86.466 116.125C86.9958 128.068 87.048 142.21 85.7026 155.885Z"
        fill="url(#paint1_linear_2974_27893)"
      />
      <path
        d="M111.303 58.18C109.336 64.7651 104.127 70.3093 100.131 73.7659C99.641 71.2533 100.165 67.6785 100.165 67.6785C100.165 67.6785 107.351 55.7364 107.69 48.4245C107.858 44.9051 110.675 46.5381 112.308 50.79C112.429 53.1313 112.065 55.6165 111.303 58.18Z"
        fill="url(#paint2_linear_2974_27893)"
      />
      <path
        d="M6.24297 165.67C-2.98534 174.994 -1.09891 187.891 2.09299 197.058C-2.70633 192.687 -5.76956 187.557 -6.12391 182.27C-7.72151 158.565 9.64236 162.393 34.5166 129.895C44.8531 116.394 47.1957 106.247 46.6771 97.7637C47.7919 101.313 48.3661 106.428 47.0637 113.526C43.9427 130.449 22.1191 149.633 6.24297 165.67Z"
        fill="url(#paint3_linear_2974_27893)"
      />
      <path
        d="M95.3476 47.9229C95.3476 47.9229 101.361 61.6963 79.9882 67.6535C58.6143 73.6106 53.0916 79.5715 51.0201 100.928C51.0201 100.928 45.1642 80.3059 58.5699 69.589C71.9747 58.871 94.0692 58.0923 95.3476 47.9229Z"
        fill="url(#paint4_linear_2974_27893)"
      />
      <path
        d="M83.733 191.032L166.202 177.639V117.984L83.733 131.377V191.032Z"
        fill="#CCE8F8"
      />
      <path
        d="M82.6369 93.1367C82.8156 93.5112 85.6411 99.5707 87.2836 100.856V97.7665C87.2836 97.7665 87.4028 97.8941 87.6326 98.0984C87.3432 97.7409 86.9943 97.4005 86.6198 97.1111C86.6198 97.1111 83.6326 94.4644 82.6369 93.1367Z"
        fill="#EBBEB8"
      />
      <path
        d="M97.7434 105.736C97.42 105.56 93.0302 104.211 93.0302 104.211C88.9614 103.824 68.2774 99.8406 48.0972 111.033C26.1008 123.233 25.1766 169.258 24.8072 176.098C24.4379 182.937 47.5134 180.131 57.8647 183.307C86.9384 192.226 77.3547 180.603 81.97 182.583C83.0091 183.029 86.329 191.805 87.1027 190.979L106.945 169.792C107.047 169.683 107.159 169.583 107.278 169.492L132.471 150.296C134.648 148.637 133.237 145.164 130.52 145.494C129.187 145.655 127.936 144.811 127.6 143.511C125.7 136.183 117.214 116.348 97.7434 105.736Z"
        fill="#21409A"
      />
      <path
        d="M97.7434 105.736C97.42 105.56 93.0302 104.211 93.0302 104.211C88.9614 103.824 68.2774 99.8406 48.0972 111.033C26.1008 123.233 25.1766 169.258 24.8072 176.098C24.4379 182.937 47.5134 180.131 57.8647 183.307C86.9384 192.226 77.3547 180.603 81.97 182.583C83.0091 183.029 86.329 191.805 87.1027 190.979L106.945 169.792C107.047 169.683 107.159 169.583 107.278 169.492L132.471 150.296C134.648 148.637 133.237 145.164 130.52 145.494C129.187 145.655 127.936 144.811 127.6 143.511C125.7 136.183 117.214 116.348 97.7434 105.736Z"
        fill="url(#paint5_linear_2974_27893)"
      />
      <path
        d="M71.8628 174.616C71.8628 174.616 58.3268 168.109 49.6375 165.9C38.7313 163.127 42.4282 161.463 42.4282 161.463C42.4282 161.463 51.486 138.173 46.6792 123.2C46.6792 123.2 50.9303 129.67 51.3005 141.684C51.6707 153.699 50.8801 158.875 57.8629 165.16C64.8458 171.445 77.9614 172.981 80.8465 174.616C83.7316 176.251 83.7316 176.251 83.7316 176.251V180.51L71.8628 174.616Z"
        fill="#21409A"
      />
      <path
        d="M104.952 131.658C104.952 131.658 105.091 120.389 103.843 117.983C103.843 117.983 109.805 125.142 110.081 128.747C110.358 132.352 104.952 131.658 104.952 131.658Z"
        fill="#21409A"
      />
      <path
        d="M71.8629 174.619C71.8629 174.619 68.0238 183.589 71.8629 186.89C75.702 190.191 79.9334 188.759 80.7896 190.238C80.7896 190.238 81.9232 189.407 79.7045 188.02C77.4866 186.634 78.5956 187.096 78.5956 187.096C78.5956 187.096 81.4602 187.373 82.4772 188.852C83.4942 190.33 85.1572 190.978 85.0653 190.238C84.9725 189.498 83.7717 187.465 82.0151 186.264C82.0151 186.264 83.1317 186.356 85.0687 187.557C87.0057 188.759 91.5631 181.18 71.8629 174.619Z"
        fill="#FCDFD8"
      />
      <path
        d="M165.775 177.278V118.485L84.1577 131.739V190.532L165.775 177.278Z"
        fill="url(#paint6_linear_2974_27893)"
      />
      <path
        d="M165.35 118.984L84.5833 132.101V190.032L165.35 176.916V118.984ZM166.201 117.984V177.64L83.7323 191.033V131.377L166.201 117.984Z"
        fill="#80BAF1"
      />
      <path
        d="M128.736 154.928L124.19 158.871C125.332 160.189 126.992 160.946 128.736 160.946C132.037 160.946 134.754 158.23 134.754 154.928C134.754 151.627 132.037 148.911 128.736 148.911C125.435 148.911 122.718 151.627 122.718 154.928H128.736Z"
        fill="#1E2A67"
      />
      <path
        d="M87.7471 116.839C79.0733 116.57 73.2538 109.12 71.7893 105.443L74.1798 92.7294C74.135 92.7008 74.0787 92.6801 74.034 92.6515C74.1339 90.8937 73.0062 87.8249 69.5795 86.05C64.244 83.2802 66.6217 76.2876 68.307 76.6045C69.9244 76.9086 72.7339 81.2728 72.9598 81.6181C72.8418 81.2325 71.4789 76.5346 74.815 72.1247C78.2883 67.5185 87.4873 71.8727 97.1442 63.392C99.1636 61.6114 100.671 59.8355 101.79 58.1482C101.587 71.4448 99.6998 95.9328 88.5067 96.5841L86.5085 107.211C86.5085 107.211 96.4315 117.109 87.7471 116.839Z"
        fill="#FDE0D9"
      />
      <path
        d="M88.6602 96.6026L87.558 102.464C86.8277 102.874 86.1868 103.063 85.6784 102.906C83.0008 102.072 80.3958 93.9546 80.3958 93.9546C83.7678 95.7446 86.4957 96.4639 88.6602 96.6026Z"
        fill="#ECBFB9"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2974_27893"
        x1="80.8877"
        :y1="size.height"
        x2="124.822"
        y2="13.6261"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#88B5FE" />
        <stop
          offset="0.9984"
          stop-color="#88B5FE"
          stop-opacity="0.15"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2974_27893"
        x1="26.6922"
        y1="137.45"
        x2="87.1068"
        y2="146.299"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#21409A"
          stop-opacity="0.43"
        />
        <stop
          offset="1"
          stop-color="#21409A"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2974_27893"
        x1="101.337"
        y1="59.6724"
        x2="111.155"
        y2="61.1104"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#21409A"
          stop-opacity="0.43"
        />
        <stop
          offset="1"
          stop-color="#21409A"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2974_27893"
        x1="-1.33495"
        y1="143.645"
        x2="41.7607"
        y2="149.958"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#21409A"
          stop-opacity="0.43"
        />
        <stop
          offset="1"
          stop-color="#21409A"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2974_27893"
        x1="52.1575"
        y1="71.3467"
        x2="92.5988"
        y2="77.2704"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#21409A"
          stop-opacity="0.43"
        />
        <stop
          offset="1"
          stop-color="#21409A"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2974_27893"
        x1="133.537"
        y1="147.122"
        x2="24.8024"
        y2="147.122"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.2372"
          stop-color="#21409A"
        />
        <stop
          offset="0.6554"
          stop-color="#215DAB"
          stop-opacity="0.5476"
        />
        <stop
          offset="0.9984"
          stop-color="#29ABE2"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2974_27893"
        x1="165.776"
        y1="154.509"
        x2="84.1576"
        y2="154.509"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#39AEE1" />
        <stop
          offset="1"
          stop-color="#2A2E6C"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ["size"]
}
</script>
